<template>
  <a-card :bordered="false">
    <div style="margin-bottom: 10px;">
      <a-row :gutter="48">
        <a-col :md="8" :sm="24">
          <span>
            <a-button @click="goAddRule()">添加节点</a-button>
          </span>
        </a-col>
      </a-row>
    </div>

    <a-table v-if="tableData.length" bordered :columns="columns" :dataSource="tableData"
      :defaultExpandAllRows="defaultExpandAllRows" :loading="tableLoading" rowKey="id">
      <span slot="name" slot-scope="text, record">
        <editable-cell :text="text" @change="onCellChange(record, 'name', $event)" />
      </span>
      <span slot="path" slot-scope="text, record">
        <editable-cell :text="text" @change="onCellChange(record, 'path', $event)" />
      </span>
      <span slot="condition" slot-scope="text, record">
        <editable-cell :text="text" @change="onCellChange(record, 'condition', $event)" />
      </span>
      <span slot="action" slot-scope="text, record">
        <a @click="goAddRule(record.id)">添加</a>
        <a-divider type="vertical" />
        <a-popconfirm v-if="tableData.length" title="确定删除该节点?" @confirm="() => onDelete(record.id)">
          <a href="javascript:;">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
  </a-card>
</template>

<script>
import { nodeTreeList, nodeUpdate, nodeDelete } from '@/api/admin'
import { EditableCell } from '@/components'

const columns = [
  {
    title: 'ID',
    dataIndex: 'id'
  },
  {
    title: '名称',
    dataIndex: 'name',
    scopedSlots: { customRender: 'name' }
  },
  {
    title: '父级ID',
    dataIndex: 'pid'
  },
  {
    title: '规则',
    dataIndex: 'path',
    scopedSlots: { customRender: 'path' }
  },
  {
    title: '附加规则',
    dataIndex: 'condition',
    scopedSlots: { customRender: 'condition' }
  },
  {
    title: '操作',
    width: '150px',
    scopedSlots: { customRender: 'action' }
  }]

export default {
  name: 'AdminRule',
  components: {
    EditableCell
  },
  data() {
    return {
      columns,
      tableData: [],
      tableLoading: false,
      defaultExpandAllRows: true
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.tableLoading = true
      new Promise((resolve, reject) => {
        nodeTreeList().then(response => {
          if (response.status === 'success') {
            resolve(response)
          } else {
            reject(response)
          }
        })
      }).then(res => {
        this.tableData = res.data
      }).catch(error => {
        this.$notification.error({
          message: error.status,
          description: error.message,
          duration: 4
        })
      }).finally(() => {
        this.tableLoading = false
      })
    },
    goAddRule(id) {
      if (id !== undefined) {
        this.$router.push({ name: 'AddNode', params: { pid: id } })
      } else {
        this.$router.push('/config/web/add-node')
      }
    },
    goAddAdmin(record) {
      console.log(record)
    },
    onCellChange(record, dataIndex, value) {
      record[dataIndex] = value
      delete record.children
      // 执行更新
      new Promise((resolve, reject) => {
        nodeUpdate(record).then(response => {
          if (response.status === 'success') {
            resolve(response)
          } else {
            reject(response)
          }
        })
      }).then(res => {
        this.$notification.success({
          message: '成功',
          description: res.message
        })
      }).catch(error => {
        this.$notification.error({
          message: '错误',
          description: error.message
        })
      })
    },
    onDelete(id) {
      // 调用删除
      new Promise((resolve, reject) => {
        nodeDelete({ 'id': id }).then(response => {
          if (response.status === 'success') {
            resolve(response)
          } else {
            reject(response)
          }
        })
      }).then(res => {
        this.$notification.success({
          message: '成功',
          description: res.message
        })
        this.loadData()
      }).catch(error => {
        this.$notification.error({
          message: '错误',
          description: error.message
        })
      })
    }
  }
}
</script>

<style lang="less">
.custom-tree {

  /deep/ .ant-menu-item-group-title {
    position: relative;

    &:hover {
      .btn {
        display: block;
      }
    }
  }

  /deep/ .ant-menu-item {
    &:hover {
      .btn {
        display: block;
      }
    }
  }

  /deep/ .btn {
    display: none;
    position: absolute;
    top: 0;
    right: 10px;
    width: 20px;
    height: 40px;
    line-height: 40px;
    z-index: 1050;

    &:hover {
      transform: scale(1.2);
      transition: 0.5s all;
    }
  }
}
</style>
<style>
.editable-cell {
  position: relative;
}

.editable-cell-input-wrapper,
.editable-cell-text-wrapper {
  padding-right: 24px;
}

.editable-cell-text-wrapper {
  padding: 5px 24px 5px 5px;
}

.editable-cell-icon,
.editable-cell-icon-check {
  position: absolute;
  right: 0;
  width: 20px;
  cursor: pointer;
}

.editable-cell-icon {
  line-height: 18px;
  display: none;
}

.editable-cell-icon-check {
  line-height: 28px;
}

.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}

.editable-cell-icon:hover,
.editable-cell-icon-check:hover {
  color: #108ee9;
}

.editable-add-btn {
  margin-bottom: 8px;
}
</style>
